.role-wrapper {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;
  cursor: pointer;
  border-radius: 10px;
  width: 80px;
  height: 70px;
  border: 2px solid transparent;
  padding: 0.5rem;
  background: #151e29;
}

.selected-role {
  border: 4px solid #2b95ac;
}

.role-image {
  width: 20px;
  margin: 0 auto;
  fill: red;
}

.role-wrapper span {
  font-size: 0.8rem;
  text-transform: capitalize;
  text-align: center;
}

.role-wrapper:not(.selected-role):hover {
  border: 2px solid #2b95ac;
}
