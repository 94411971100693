@font-face {
  font-family: 'Karasha';
  src: url('https://res.cloudinary.com/keyzen/raw/upload/v1677812776/fonolo/Karasha_DEMO_lgn7lr.ttf')
    format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  min-height: 100vh;
  background-image: url('../assets/app-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
}

/* Oculta el badge de Google reCaptcha */
.grecaptcha-badge {
  visibility: hidden;
}
