.white {
  color: white;
}

.bold {
  font-weight: bold;
}

.info {
  color: #31cff2;
  font-weight: bold;
}

.p {
  font-size: medium;
}

/* ShowroomCard */

.like-list-p {
  border-bottom: 1px solid white;
}

.pdf-p {
  margin: auto auto;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  color: #0d1116;
}

.name-p {
  text-decoration: none;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  margin: 0;
}

.skills-title-score-p {
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #637daf;
}

.disp-res-title-p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  color: #fff;
}

.disp-res-p {
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #637daf;
  margin-top: -3px;
}

.rol-p {
  display: flex;

  margin-left: 8px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #31cff2;
}

.nivel-p {
  display: flex;

  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
  color: #637daf;
}

.metinca-p {
  margin-top: 2px;
  color: #31cff2;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: 49px;
  height: 16px;
}

.metinca-p-on {
  margin-top: 2px;
  color: #0d1116;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  width: 49px;
  height: 16px;
}

/* ShowroomCard */

/*vista candidato ////////////////////////////////////////////////*/

.details-personal-data-title {
  margin-top: 20px;
  padding-left: 10px;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}

.details-skills-title {
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  color: #31cff2;
}

.details-skills-titles-sub {
  display: flex;
  justify-content: center;

  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  color: #ffffff;
}

/* virtudes y defectos */
.virtues-defects-button-text {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #0d1116;
}

.virtues-defects-text {
  margin: 0 auto;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  display: flex;
  align-items: center;

  color: #fff;
}

/*resumen */
.resume-title {
  display: flex;
  align-items: center;

  color: #ffffff;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

.resume-text {
  display: grid;
  place-content: center;

  color: #fff;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

/*glossary*/

.glossary-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  text-align: center;

  color: #31cff2;
}

.glossary-skills-name {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.glossary-subskills-title {
  margin: 0 auto;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #31cff2;
}

.glossary-subtitle {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height, or 120% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.glossary-question {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */

  display: flex;
  align-items: center;

  color: #637daf;
}

.glossary-button-text {
  margin: 0 auto;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  color: #637daf;
}

.glossary-answer-description {
  margin: 0 auto;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: center;

  color: #637daf;
}

/*vista candidato ////////////////////////////////////////////////*/

/*listMentoring //////////////////////////*/

.lm-filtros {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #ffffff;
}

/*listMentoring //////////////////////////*/

/*kam ///////////////////////////// */
.kam-cell-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 1px;
  color: #ffffff;
}

.kam-title-interview {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.kam-title-interview-modal {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: #ffffff;

  margin-bottom: 1rem;
  text-align: center;
}

.kam-card-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: gray;

  text-align: center;
  margin: 12px;
}

.kam-card-edit {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: gray;

  text-align: center;
  margin: 12px;
}

/*kam ///////////////////////////// */

/*skillcompmenu ///////////////////////////// */

/*skillcompmenu ///////////////////////////// */

/*questionscomp ///////////////////////////// */
.ac-questions {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1px;
  color: gray;
}

/*questionscomp ///////////////////////////// */

/*contents  ///////////////////////////// */

.contents-title {
  font-family: 'Karasha', 'Roboto', serif;
  font-style: normal;
  font-weight: 600;
  font-size: 86px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: #ffffff;

  margin-bottom: 1rem;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .contents-title {
    font-size: 56px;
  }
}
.cc-title {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: #ffffff;

  margin-bottom: 1rem;
  text-align: center;
}

.contents-activities-title {
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 100% */

  color: #ffffff;

  margin-bottom: 1rem;
  text-align: center;
}

/*contents ///////////////////////////// */

.dc-description {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #637daf;
}
