/* src/components/ui/calendar/WeekdayTimePicker.css */

.weekday-time-picker {
  font-family: Arial, sans-serif;

  border-radius: 10px;
}

.days-container {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se colapsen a filas */
  margin-bottom: 20px;
  max-width: 100%;
}

.day-label {
  display: flex;
  align-items: center;
  margin: 5px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 1px;
  cursor: pointer;
  transition:
    background-color 0.3s,
    opacity 0.3s;
  flex: 1 0 100px;
}

.day-label:hover {
  /* Cambia el color al pasar el mouse, aumentar opacidad*/
  background-color: #d9d9d9; /* Color de fondo más oscuro */
  opacity: 1.2; /* Aumenta la opacidad en un 20% */
}

.day-schedule {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 1px;
}

input[type='time'] {
  margin-right: 10px;
}

button {
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.styled-button {
  background-color: #3f51b5; /* Color de fondo */
  color: white; /* Color del texto */
  border: none; /* Sin borde */
  border-radius: 4px; /* Bordes redondeados */
  padding: 10px 20px; /* Espaciado interno */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  transition: background-color 0.3s; /* Transición suave para el color de fondo */
}

.styled-button:hover {
  background-color: #303f9f; /* Color de fondo al pasar el mouse */
}

.message {
  font-size: 1rem;
}
