.textBox {
  height: 202px;
  width: 420px;
  position: absolute;
  border-radius: 16px;
  border-style: solid;
  border-color: #31cff2;
  background-color: black;
  color: #31cff2;
  margin-top: 4rem;
  margin-left: 4rem;
}

.textBox::before {
  content: 'Trabajo en equipo';
  position: absolute;
  text-align: center;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  padding: 0 10px;
  color: #31cff2;
  background-color: black;
}

.box-xl {
  background-color: red;
}

.wrapper {
  display: flex;
  margin-left: 5%;
}

@media screen and (max-width: 999px) {
  .wrapper {
    flex-direction: column;
  }
}

.witiDojo {
  display: flex;
}

.avatarBox {
  display: flex;

  align-self: center;
}

.menteeName {
  display: flex;
}

@media screen and (max-width: 768px) {
  .menteeName {
    justify-content: center;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

@media screen and (max-width: 999px) {
  .head {
    display: flex;
    flex-direction: column;
    justify-self: center;
  }
}

.menteeData {
  width: 100%;
  justify-content: space-around;
}

.witiPoints {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

@media screen and (max-width: 768px) {
  .witiPoints {
    justify-content: center;
  }
}

.menteeAttributes {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 768px) {
  .menteeAttributes {
    grid-template-columns: 1fr 1fr;
    place-items: center;
    gap: 5px;
    border-radius: 15px;
    background-color: #0d1116;
  }
}

.attributeBox {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  justify-content: flex-start;
}

@media screen and (max-width: 768px) {
  .attributeBox {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
    place-items: center;
  }
}

.actionsBox {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 2%;
  gap: 10px;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .actionsBox {
    flex-direction: column;
    align-items: center;
  }
}

/* ShowRoomCandidate ///////////////////////////////////////////////////////*/
.showroom-container {
  display: flex;

  font-style: normal;
  background-position: center;
  padding: 0;
}

.cards-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 24px;
}
/* ShowRoomCandidate ///////////////////////////////////////////////////////*/

/* ShowroomCard /////////////////////////////////////////////////////////////////////////////*/
.card-dark {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 16px;
  max-width: 1200px;
  min-width: 1200px;
  margin-bottom: 24px;
  gap: 12px;
  background: rgba(55, 80, 108, 0.081);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}

.personal-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.personal-public-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.personal-public-info2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: self-start;
}

.name-like {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
  padding: 0;
}

.rol-nivel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  padding: 0;
}

.name-like-rol-nivel {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  padding: 0;
}

.witi-like {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 81px;
  height: 24px;
  border: 1px solid #31cff2;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}

.witi-like:hover {
  background: #20879e;
  border: 1px solid #31cff2;
}

.witi-like-on {
  position: absolute;
  right: 4rem;
  top: 1rem;
  width: 81px;
  height: 24px;
  background: #31cff2;
  border: 1px solid #31cff2;
  border-radius: 4px;
  margin: 5px;
  cursor: pointer;
}

.witi-like-on:hover {
  background: #20879e;
  border: 1px solid #31cff2;
}

.like-icon {
  display: flex;
  width: 16px;
  height: 16px;
  margin: 0;
  padding-top: 5px;
  padding-left: 6px;
}

.disp-nac-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.disp-nac-container-margin {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 8px;
}

.disp-container {
  display: flex;
  flex-direction: column;
  margin: 0 20px 0 0;
}

.public-data {
  display: flex;
  flex-direction: column;
  margin: 0 0 0 0;
}

.skills-container {
  display: flex;
  flex-wrap: wrap;

  align-items: center;

  margin-bottom: 5px;
}

.skills-title-score-container {
  display: flex;
  flex-direction: row;
  height: 24px;
  margin-right: 15px;
}

.skills-title-container {
  display: flex;

  align-items: center;
  padding: 12px 6px 0 10px;
  background: rgba(19, 39, 64, 0.72);
  border-radius: 4px 0 0 4px;
  width: 116px;
  height: 24px;
}

.skills-score-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;

  width: 32px;
  height: 24px;
  background: #0d1116;
  border: 1px solid rgba(19, 39, 64, 0.72);
  border-radius: 0 4px 4px 0;
}

.pdf-button {
  display: flex;
  margin: 5px 18px 5px 5px;
  width: 41px;
  height: 24px;

  background: #637daf;
  border-radius: 4px;
  cursor: pointer;
}

.info-like-list {
  position: absolute;
  z-index: 100 !important;
  top: 10px;
  right: 150px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  color: white;
  border-radius: 5px;
  font-size: 12px;
}

@media only screen and (max-width: 768px) {
  .info-like-list {
    display: none;
  }
}
/* ShowRoomCard ///////////////////////////////////////////////////////*/

/*vista candidato ////////////////////////////////////////////////*/
.details-candidate-container {
  display: flex;
  background-position: center;
  font-style: normal;
  padding: 0 24px 24px 24px;
}

.details-candidate-container * {
  font-style: normal;
}

.details-title-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.details-title-skills-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 20px;
}

.details-title-subskills-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;

  gap: 20px;
}

.details-radar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px;
  flex: 1;
  min-width: 49%;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

@media only screen and (max-width: 1065px) {
  .details-radar-container {
    display: flex;

    width: 99.6%;
    flex: none;
  }
}

/* virtudes y defectos */

/*glosario*/
.glossary-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 0;
  margin-top: 24px;
}

.glossary-skills {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  width: 100%;
  margin: 12px;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}

.glossary-skills-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;
  min-width: 100%;
}

.glossary-display-none {
  display: none;
}

.glossary-skills-subskills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  min-width: 48%;
  margin: 12px;
  padding-top: 12px;
  background: rgba(55, 80, 108, 0.24);
  backdrop-filter: blur(5px);

  border-radius: 8px;
}

.glossary-skill-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;
  min-width: 100%;
  gap: 20px;
  padding: 20px;
}

/**/
.glossary-subskills-card-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12px;
}

.glossary-subskill-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: rgba(9, 11, 27, 0.24);
  border-radius: 8px;

  flex: 1;
  min-width: 99%;
}

.glossary-subskill-subtitle-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.glossary-button-text-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}

.glossary-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  background: rgba(55, 80, 108, 0.24);
  border-radius: 4px;
}
/*vista candidato ////////////////////////////////////////////////*/

/*listMentoring //////////////////////////*/
.lm-container {
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  padding: 20px;
}

/* navbar height styles reference  */
@media screen and (min-width: 1231px) {
  .lm-container {
    min-height: calc(100vh - 90px);
  }
}
@media screen and (min-width: 901px) and (max-width: 1230px) {
  .lm-container {
    min-height: calc(100vh - 140px);
  }
}
@media screen and (max-width: 900px) {
  .lm-container {
    min-height: calc(100vh - 50px);
  }
}

.lm-container * {
  font-style: normal;
}

.lm-filter-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 16px 24px;

  flex-flow: row wrap;
  background: rgba(55, 80, 108, 0.12);
  /* Bordes Glass */

  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  border-radius: 10px;
}

.lm-filter-text-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.lm-grid-container {
  height: 550px;
  width: 100%;
  margin-bottom: 40px;
}

.lm-grid-sub-container {
  display: flex;
  height: 100%;
}

.lm-grid-spinner-container {
  display: flex;
  flex: 1;
}
/*listMentoring //////////////////////////*/

/*kam ///////////////////////////// */
.kam-container {
  display: flex;
  flex-direction: column;
  font-style: normal;
  background-position: center;
  padding: 24px;
  height: 100%;
}

.box-interview {
  display: flex;
  justify-content: center;
}

.box-typo-textfield {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  gap: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  padding: 24px;
}

.kam-form-interview {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px 16px;
  width: 100%;
  margin-bottom: 24px;
  gap: 12px;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.kam-interview-select-modal {
  display: flex;
  flex-wrap: wrap;
}

.kam-card {
  font-style: normal;
  width: 60vw;
  padding: 24px;
  gap: 12px;
  background: transparent;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  margin-bottom: 2px;
}

.kam-card-subcontainer {
  display: flex;
  flex-direction: row;
}

.kam-question-container {
  display: flex;
  width: 100%;
}
/*kam ///////////////////////////// */

/*questionscomp ///////////////////////////// */
.questions-comp-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px;
  gap: 12px;
  background: transparent;
  border: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.questions-comp-title-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 24px 24px 14px 24px;
  gap: 12px;
  background: transparent;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}
/*questionscomp ///////////////////////////// */

/*mentee ///////////////////////////// */
.mentee-container {
  display: flex;
  flex-direction: column;
  font-style: normal;
  background-position: center;
  padding: 24px;
  height: 100%;
}
/*mentee ///////////////////////////// */

/*mentor ///////////////////////////// */
.mentor-container {
  display: flex;
  flex-direction: column;
  font-style: normal;
  background-position: center;
  padding: 24px;
  height: 100%;
}
/* navbar height styles reference  */
@media screen and (min-width: 1231px) {
  .mentor-container {
    min-height: calc(100vh - 90px);
  }
}
@media screen and (min-width: 901px) and (max-width: 1230px) {
  .mentor-container {
    min-height: calc(100vh - 140px);
  }
}
@media screen and (max-width: 900px) {
  .mentor-container {
    min-height: calc(100vh - 50px);
  }
}

.mentor-button-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.mentor-row-container {
  display: flex;
  flex-direction: row;
}
.mentor-question-container {
  display: flex;
  width: 100%;
}
/*mentor ///////////////////////////// */

/*objectives ///////////////////////////// */
.obj-fieldset-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@media screen and (max-width: 850px) {
  .obj-fieldset-container {
    flex-wrap: wrap;
  }
}

.sliderFade {
  opacity: 0;
  transition-property: opacity, left;
  transition-duration: 1s;
}

.slider {
  opacity: 1;
  transition-property: opacity, right;
  transition-duration: 1s;
}
/*objectives ///////////////////////////// */

/*createContents ///////////////////////////// */
.contents-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-position: center;
  background-size: cover;
  font-style: normal;
  padding: 24px;
  box-sizing: border-box;
  height: 100%;
}

.contents-create-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-style: normal;
  width: 100%;
}

.contents-general-atributes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
}

.contents-atributes-container {
  display: flex;
  flex-direction: column;
  width: 48%;
  flex: 1;
}

.contents-button-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 78.5%;
}

.contents-atributes-container,
.contents-button-container {
  gap: 20px;
  align-items: center;
  padding: 32px;

  margin: 12px;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.contents-activities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  padding: 24px;
  height: auto;
  background: transparent;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.contents-activity {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 30%;
  gap: 10px;
  height: auto;
  background: transparent;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  border-radius: 8px;
}

.content-disp-none {
  display: none;
}

.content-activities-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: rgba(9, 11, 27, 0.24);
  border-radius: 8px;
  gap: 12px;
  overflow: auto;
}
/*createContents ///////////////////////////// */

/*HeaderContents ///////////////////////////// */
.hc-bg-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  margin-bottom: 2rem;
  background-image: url('../../MenteeView/assets/mentee-profile-background.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: top;
  background-position-x: center;
  width: 100%;
  height: 430px;
  border-radius: 30px;
  padding: 2rem;
}

.ch-ninja-container {
  display: flex;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;
}

.ch-pencil-container {
  display: flex;
  margin-top: 90px;
  margin-bottom: -90px;
  flex-direction: row;
  align-self: flex-end;
  justify-self: flex-end;
  cursor: pointer;
  height: 50px;
}

@media screen and (max-width: 636px) {
  .ch-pencil-container {
    margin-top: 90px;
    margin-bottom: -35px;
  }
}

@media screen and (max-width: 480px) {
  .ch-pencil-container {
    justify-self: center;
    align-self: center;
    margin-top: 90px;
    margin-bottom: -35px;
  }
}
/*HeaderContents ///////////////////////////// */

/*displaycontent ///////////////////////////// */
.dc-activities-subcontainer {
  display: flex;
  flex-direction: column;
}

.dc-content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: rgba(9, 11, 27, 0.24);
  border-radius: 8px;
  gap: 12px;
  flex: 1;
}

.dc-button-text-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0;
  gap: 12px;
  flex-wrap: wrap;
}

.dc-activities-button-text-container {
  display: flex;
  gap: 1px;
  flex-direction: row;

  padding: 0;
}

.dc-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 78.5%;
  margin: 12px;
  background: rgba(55, 80, 108, 0.12);
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: rgba(49, 207, 242, 0.32);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.24);
  backdrop-filter: blur(5px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 8px;
}

.dc-width {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dc-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: stretch;

  gap: 24px;
}
/*displaycontent ///////////////////////////// */
